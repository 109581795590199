/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Qrcode from 'vux/src/components/qrcode';
export default {
  components: {
    Qrcode: Qrcode
  },
  data: function data() {
    return {
      showFlag: false,
      timer: null,
      //定时器
      value: ''
    };
  },
  computed: {},
  methods: {
    // 生成二维码
    getQrcode: function getQrcode() {
      var _this = this;

      this.showFlag = false;
      this.$api.Home.getQrcode({
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this.$utils.Tools.loadHide();

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);

          setTimeout(function () {
            _this.$router.go(-1);
          }, 2000);
        } else {
          _this.value = data.codeStr;
          _this.showFlag = true;
        }
      }).catch(function () {});
    }
  },
  created: function created() {
    var _this2 = this;

    this.$utils.Tools.loadShow();
    clearInterval(this.timer);
    this.getQrcode();
    this.timer = setInterval(function () {
      _this2.getQrcode();
    }, 30000);
  },
  destroyed: function destroyed() {
    clearInterval(this.timer);
  }
};